import { Container, TitleWithBackButton } from '@/components';
import { COLORS, PADDINGS, SOCIAL_LINKS, TYPOGRAPHY } from '@/constants';
import { SliderWithInput, Tooltip, UiLink } from '@/ui/components';
import { Info, Man, Woman } from '@/ui/iconComponents';
import currency from 'currency.js';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

enum Gender {
  man = 'man',
  woman = 'woman',
}

const Calculator: FC = () => {
  const [ageValue, setAgeValue] = useState(45);
  const [averageSalary, setAverageSalary] = useState(30000);
  const [experience, setExperience] = useState(5);
  const [gender, setGender] = useState(Gender.man);
  const genderCoef = gender === Gender.man ? 1.1 : 1.05;
  const percentageOfDeducations = 0.06;
  const monthsOfYear = 12;

  const totalSum =
    percentageOfDeducations *
    averageSalary *
    monthsOfYear *
    experience *
    genderCoef *
    ((ageValue - 1) / (ageValue + 1));

  const totalSumFormated = currency(totalSum, {
    precision: 2,
    separator: ' ',
    symbol: '₽',
    pattern: '# !',
    decimal: ',',
  }).format();

  const handleGenderButtonClick = (value: Gender) => () => setGender(value);

  return (
    <Root>
      <StyledContainer>
        <Wrapper>
          <StyledTitleWithBackButton text="Рассчитать ваши пенсионные накопления" />
          <FieldsWrapper>
            <InputsWrapper>
              <GenderWrapper>
                <GenderLabel>Ваш пол:</GenderLabel>
                <GenderButtons>
                  <GenderButton
                    $isActive={gender === Gender.man}
                    onClick={handleGenderButtonClick(Gender.man)}
                  >
                    <GenderIcon>
                      <Man
                        width={16}
                        height={16}
                        color={
                          gender === Gender.man
                            ? COLORS.primaryDefault
                            : COLORS.grayDarker
                        }
                      />
                    </GenderIcon>
                    Мужской
                  </GenderButton>
                  <GenderButton
                    $isActive={gender === Gender.woman}
                    onClick={handleGenderButtonClick(Gender.woman)}
                  >
                    <GenderIcon>
                      <Woman
                        width={16}
                        height={16}
                        color={
                          gender === Gender.woman
                            ? COLORS.primaryDefault
                            : COLORS.grayDarker
                        }
                      />
                    </GenderIcon>
                    Женский
                  </GenderButton>
                </GenderButtons>
              </GenderWrapper>
              <StyledSliderWithInput
                value={ageValue}
                onChangeValue={setAgeValue}
                label="Ваш возраст"
                minBorder={25}
                minBorderText="от 25 лет"
                maxBorder={55}
                maxBorderText="до 55 лет"
              />
              <StyledSliderWithInput
                value={experience}
                onChangeValue={setExperience}
                label="Ваш стаж*"
                minBorder={1}
                minBorderText="от 1 года"
                maxBorder={12}
                maxBorderText="до 12 лет"
              />
              <StyledSliderWithInput
                value={averageSalary}
                onChangeValue={setAverageSalary}
                label="Ваша средняя зарплата*"
                minBorder={8000}
                minBorderText="от 8000 ₽"
                maxBorder={75000}
                step={500}
                maxBorderText="75 000+ ₽"
              />
              <InputsDescription>
                * - c 2002 по 2013 включительно
              </InputsDescription>
            </InputsWrapper>
            <TotalSumWrapper>
              <TotalSum>
                <TotalSumTitle>{totalSumFormated}</TotalSumTitle>
                <Info
                  color={COLORS.gray}
                  data-tip="Примерная сумма ваших накоплений"
                />
                <Tooltip effect="solid" />
              </TotalSum>
              <TotalSumDescription>
                Общая сумма ваших накоплений
              </TotalSumDescription>
              <StyledUiLink
                text="Хочу получить выплату"
                size="large"
                link={SOCIAL_LINKS.whatsapp}
              />
            </TotalSumWrapper>
          </FieldsWrapper>
        </Wrapper>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.grayLighter};
`;

const StyledContainer = styled(Container)`
  padding-top: 48px;
  padding-bottom: ${PADDINGS.paddingBetweenSectionsLarge};
  @media (max-width: 1024px) {
    padding-bottom: 64px;
  }
`;

const Wrapper = styled.div`
  background-color: ${COLORS.white};
  border-radius: 18px;
  padding: 64px 8.41% 88px;
  @media (max-width: 1350px) {
    padding-left: 5%;
    padding-right: 5%;
  }
  @media (max-width: 1024px) {
    padding: 0;
    background-color: transparent;
  }
`;

const StyledTitleWithBackButton = styled(TitleWithBackButton)`
  margin-bottom: 48px;
  @media (max-width: 1024px) {
    margin-bottom: 32px;
  }
`;

const FieldsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const InputsWrapper = styled.div`
  width: 100%;
  max-width: 588px;
  margin-right: 24px;
  @media (max-width: 1100px) {
    max-width: 500px;
  }
  @media (max-width: 1024px) {
    max-width: none;
    margin-right: 0;
    margin-bottom: 48px;
  }
`;

const InputsDescription = styled.p`
  ${TYPOGRAPHY.caption1Regular14};
  color: ${COLORS.grayDarker};
`;

const GenderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;
  }
`;

const GenderLabel = styled.p`
  ${TYPOGRAPHY.body1SemiBold18};
  color: ${COLORS.black};
  margin-right: 8px;
  @media (max-width: 1024px) {
    margin-right: 0;
    margin-bottom: 12px;
  }
`;

const GenderButtons = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const GenderButton = styled.button<{ $isActive: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 180px;
  ${TYPOGRAPHY.body2Medium16};
  color: ${({ $isActive }) =>
    $isActive ? COLORS.primaryDefault : COLORS.grayDarker};
  background-color: ${({ $isActive }) =>
    $isActive ? COLORS.primaryLight : COLORS.grayLight};
  padding: 8px;
  border-radius: 12px;
  margin-right: 8px;
  :last-child {
    margin-right: 0;
  }
  @media (max-width: 1024px) {
    max-width: 168px;
  }
`;

const GenderIcon = styled.span`
  display: flex;
  margin-right: 8px;
`;

const StyledSliderWithInput = styled(SliderWithInput)`
  margin-bottom: 45px;
  :last-child {
    margin-bottom: 0;
  }
`;

const TotalSumWrapper = styled.div``;

const TotalSum = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const TotalSumTitle = styled.p`
  ${TYPOGRAPHY.title3SemiBold32};
  color: ${COLORS.black};

  margin-right: 8px;
`;

const TotalSumDescription = styled.p`
  ${TYPOGRAPHY.body1Regular18};
  color: ${COLORS.grayDarker};
  margin-bottom: 32px;
`;

const StyledUiLink = styled(UiLink)`
  max-width: none;
`;

export default Calculator;
