import React from 'react';
import { MainLayout } from '@/layouts';
import { Seo } from '@/components';
import { SeoData } from '@/types';
import retireds from '@/assets/images/retireds.png';
import { Calculator } from '@/pageComponents/CalculatorPage';
import { COLORS } from '@/constants';

export default function CalculatorPage() {
  const seoData: SeoData = {
    metaTitle: 'Социальный проект «Выплаты»',
    metaDescription: 'Рассчитайте положенную вам выплату в нашем калькуляторе',
    keywords: 'пенсия, деньги, выплата',
    sharedImage: {
      url: retireds,
    },
  };
  return (
    <MainLayout backgroundColor={COLORS.grayLighter} fullHeight={true}>
      <Seo data={seoData} />
      <Calculator />
    </MainLayout>
  );
}
